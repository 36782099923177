import { directus } from "./directus";
import {
  createItem,
  deleteUser,
  readItem,
  readItems,
  readUser,
  updateItem,
  updateUser,
  uploadFiles,
  refresh,
} from "@directus/sdk";

import axios from "axios";
import {
  GOOGLE_MAPS_API_KEY,
  USE_GMAP_SDK,
  USE_PLACE_API,
  USE_GEO_API,
} from "./constants";

const isUsingGoogleMaps = USE_GMAP_SDK && Boolean(GOOGLE_MAPS_API_KEY);

export const ApiUtils = {
  call: async (callFunc) => {
    try {
      const res = await callFunc();
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      const errorJson = error?.errors ? error?.errors[0] : error?.raw;
      const auth_token = localStorage.getItem("auth_token");
      const refresh_token = localStorage.getItem("refresh_token");
      if (error?.response?.status === 401 && auth_token) {
        try {
          const refresh_data = await directus.request(
            refresh("json", refresh_token)
          );
          await directus.setToken(refresh_data.access_token);
          localStorage.setItem("auth_token", refresh_data.access_token);
          localStorage.setItem("refresh_token", refresh_data.refresh_token);
          const retryRes = await callFunc();
          return {
            success: true,
            data: retryRes,
          };
        } catch (err) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("adminMode");
          console.log("Error refresh token");
          window.location.href = "/";
        }
      }
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  get: async (path, options = {}) => {
    const { params = null, useAuth = true } = options;
    const apiOptions = {};
    if (useAuth) {
      apiOptions["headers"] = {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      };
    }
    if (params) {
      apiOptions["params"] = params;
    }
    try {
      const res = await directus.request(() => ({
        path: path,
        method: "GET",
        ...apiOptions,
      }));
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      const errorJson = error?.errors ? error?.errors[0] : error?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  post: async (path, options = {}) => {
    const { body = null, useAuth = true } = options;
    const apiOptions = {};
    if (useAuth) {
      apiOptions["headers"] = {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      };
    }
    try {
      const res = await directus.request(() => ({
        path: path,
        method: "POST",
        ...apiOptions,
      }));
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      const errorJson = error?.errors ? error?.errors[0] : error?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  postWithJson: async (path, options = {}) => {
    const { body = null, useAuth = true } = options;
    const apiOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    if (useAuth) {
      apiOptions.headers.Authorization = `Bearer ${localStorage.getItem(
        "auth_token"
      )}`;
    }

    try {
      const res = await directus.request(() => ({
        path: path,
        ...apiOptions,
      }));
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      const errorJson = error?.errors ? error?.errors[0] : error?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },

  axios: async (axiosFunc) => {
    try {
      const { data } = await axiosFunc();
      return {
        success: true,
        data,
      };
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.error?.message,
      };
    }
  },
};

export const ApiHelper = {
  sendVerificationEmail: () => ApiUtils.post("/verify-email"),
  verifyIdentity: (laser_code) =>
    ApiUtils.postWithJson("/verify-identity", { body: { laser_code } }),
  updateProfile: (profileId, data = {}) =>
    ApiUtils.call(() =>
      directus.request(updateItem("user_profiles", profileId, data))
    ),
  updateUser: (userId, data = {}) =>
    ApiUtils.call(() => directus.request(updateUser(userId, data))),
  getUser: (userId) => ApiUtils.call(() => directus.request(readUser(userId))),

  getPOI: (lat, lon) =>
    isUsingGoogleMaps
      ? ApiUtils.axios(() =>
          axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
            params: {
              latlng: `${lat},${lon}`,
              key: GOOGLE_MAPS_API_KEY,
              language: "th",
            },
          })
        )
      : ApiUtils.axios(() =>
          axios.get("https://nominatim.openstreetmap.org/reverse", {
            params: { lat, lon, format: "jsonv2" },
          })
        ),

  uploadFile: (fileFormData) =>
    ApiUtils.call(() => directus.request(uploadFiles(fileFormData))),

  addEvent: ({
    title,
    type,
    image = null,
    video = null,
    description = null,
    audio_title = null,
    audio_description = null,
    audio_other = null,
    location,
    location_title,
    custom_type,
  }) =>
    ApiUtils.call(() =>
      directus.request(
        createItem("user_events", {
          title,
          type,
          image,
          description,
          audio_title,
          audio_description,
          audio_other,
          location,
          location_title,
          custom_type,
          video,
        })
      )
    ),
  getRegions: () =>
    ApiUtils.call(() =>
      directus.request(
        readItems("addresses", {
          groupBy: ["region"],
          sort: ["region"],
          limit: -1,
        })
      )
    ),
  getProvinces: (region) =>
    ApiUtils.call(() =>
      directus.request(
        readItems("addresses", {
          groupBy: ["province_id", "province_th"],
          sort: ["province_id"],
          limit: -1,
          filter: { region: { _eq: region } },
        })
      )
    ),
  getDistricts: (province) =>
    ApiUtils.call(() =>
      directus.request(
        readItems("addresses", {
          groupBy: ["district_id", "district_th_short"],
          sort: ["district_id"],
          limit: -1,
          filter: { province_th: { _eq: province } },
        })
      )
    ),
  getTambons: (district) =>
    ApiUtils.call(() =>
      directus.request(
        readItems("addresses", {
          groupBy: ["tambon_id", "tambon_th_short"],
          sort: ["tambon_id"],
          limit: -1,
          filter: { district_th_short: { _eq: district } },
        })
      )
    ),
  getStations: (lat, long) =>
    ApiUtils.get("nearby-stations", { params: { lat, long } }),
  getNotifications: async (lat, long) =>
    await ApiUtils.get("event-notifications", {
      params: { lat, long },
      sort: ["-date-created"],
    }),
  getFAQs: () => ApiUtils.call(() => directus.request(readItems("faqs"))),
  getCrimeRisk: ({ lat, long, province, district, tambon, date, time }) =>
    ApiUtils.postWithJson("/crime-risk", {
      body: { lat, long, province, district, tambon, date, time },
    }),
  deleteAccount: (userId) =>
    ApiUtils.call(() => directus.request(deleteUser(userId))),
  getRiskAdvices: () =>
    ApiUtils.call(() => directus.request(readItems("risk_advices"))),
  getEvents: () =>
    ApiUtils.call(() =>
      directus.request(readItems("user_events", { sort: ["-date_created"] }))
    ),
  getEventById: (eventId) =>
    ApiUtils.call(() => directus.request(readItem("user_events", eventId))),
  updateEvent: (eventId, data = {}) =>
    ApiUtils.call(() =>
      directus.request(updateItem("user_events", eventId, data))
    ),
  getEventNotification: () =>
    ApiUtils.call(() =>
      directus.request(
        readItems("event_notifications", { sort: ["-date_created"] })
      )
    ),
  addEventNotification: ({
    title,
    type,
    image = null,
    description = null,
    location,
    location_title,
    custom_type,
    alert_distance,
    damage_level,
  }) =>
    ApiUtils.call(() =>
      directus.request(
        createItem("event_notifications", {
          title,
          type,
          image,
          description,
          location,
          location_title,
          custom_type,
          alert_distance,
          damage_level,
          notification_update: 0,
        })
      )
    ),
  getChatbotResponse: async (ques) =>
    await ApiUtils.get("chatbot", { params: { ques } }),
  cancelNotification: (eventId) =>
    ApiUtils.call(() =>
      directus.request(
        updateItem("event_notifications", eventId, { notification_update: 1 })
      )
    ),

  postMapFindPlace: async ({ input }) => {
    return await ApiUtils.postWithJson(
      "/crime-risk/google/places/autocomplete",
      {
        body: { input },
      }
    );
  },

  postMapPlaceID: async ({ input }) => {
    return await ApiUtils.postWithJson("/crime-risk/google/places/details", {
      body: { input },
    });
  },

  postGeoReverse: async ({ latitude, longitude }) => {
    return await ApiUtils.postWithJson("/crime-risk/google/geocode/reverse", {
      body: { latitude, longitude },
    });
  },

  postCrimeRules: async ({ date, time, gender, age, province }) => {
    return await ApiUtils.postWithJson("/crime-rule/", {
      body: { gender, age, province, date, time },
    });
  },
};
