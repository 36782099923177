import React, { createContext, useState, useEffect, useContext } from "react";
import { AuthHelper } from "../libs/auth";
import { ApiHelper } from "../libs/api";
import { PEOPLE_ROLE } from "../libs/constants";
import { directus } from "../libs/directus";
import { refresh } from "@directus/sdk";
import { saveAgeAndGender } from "../libs/saveAgeGender";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [userProfileId, setProfileUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authStates, setAuthStates] = useState({
    isAuthenticated: false,
    isVerifiedEmail: false,
    hasAcceptedTerms: false,
    isIdentityVerified: false,
    isAdmin: false,
    adminMode: false,
    birthday: null,
    gender: "",
  });

  const changeAdminMode = (isChange) => {
    if (!isChange) {
      localStorage.removeItem("adminMode");
      setAuthStates({ ...authStates, adminMode: false });
    } else {
      localStorage.setItem("adminMode", JSON.stringify(true));
      setAuthStates({ ...authStates, adminMode: true });
    }
  };

  const checkAuth = async () => {
    const authToken = localStorage.getItem("auth_token");
    //const refreshToken = localStorage.getItem("refresh_token");
    let newAuthStates = { ...authStates };

    if (authToken) {
      newAuthStates = { ...newAuthStates, isAuthenticated: true };
      await directus.setToken(authToken);
      const { success, data } = await AuthHelper.me();
      const meData = data;
      // people role
      if (success) {
        const userId = meData.id;
        setUserId(userId);
        const { success, data, error } = await AuthHelper.getUserProfile(
          userId
        );
        const profile = data[0];
        /* console.log("profile",profile); */
        const { age, formattedGender } = saveAgeAndGender(
          profile.birthday,
          profile.gender
        );

        if (success) {
          if (profile) {
            setProfileUserId(profile.id);
            newAuthStates = {
              ...newAuthStates,
              isVerifiedEmail:
                profile.verified_email ||
                profile.line_verified_identity ||
                profile.thaid_verified_identity ||
                false,
              hasAcceptedTerms: profile.accepted_term || false,
              isIdentityVerified:
                profile.verified_identity ||
                profile.thaid_verified_identity ||
                false,
              birthday: age || "",
              gender: formattedGender || "",
            };
          }
        } else if (error === "You are not authorized to access this page") {
          console.log("Error: not authorized");
        }
        // admin role
        if (meData.role.toLowerCase() !== PEOPLE_ROLE.toLowerCase()) {
          newAuthStates = {
            ...newAuthStates,
            isAdmin: true,
          };
        }
      } else {
        console.log("Internal Error");
      }
    } else {
      newAuthStates = {
        ...newAuthStates,
        isAuthenticated: false,
        isVerifiedEmail: false,
        hasAcceptedTerms: false,
        isIdentityVerified: false,
        isAdmin: false,
        adminMode: false,
      };
    }

    const isAdminMode = localStorage.getItem("adminMode");
    if (isAdminMode) {
      newAuthStates = { ...newAuthStates, adminMode: true };
    }

    setAuthStates(newAuthStates);
    setLoading(false);
  };

  const login = async (email, password) => {
    localStorage.removeItem("adminMode");
    const { success, data, error } = await AuthHelper.login(email, password);
    if (success) {
      await setToken(data.access_token, data.refresh_token);
    }
    return { success: success, error: error };
  };

  const setToken = async (auth_token, refresh_token) => {
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("refresh_token", refresh_token);
    await directus.setToken(auth_token);
    await checkAuth();
  };

  const refreshToken = async (refresh_token) => {
    const data = await directus.request(refresh("json", refresh_token));
    return data;
  };

  const logout = async () => {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      const { success } = await AuthHelper.logout(refresh_token);
    } catch (err) {
      console.log("Logout error");
    } finally {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      setAuthStates({
        isAuthenticated: false,
        isVerifiedEmail: false,
        hasAcceptedTerms: false,
        isIdentityVerified: false,
        isAdmin: false,
      });
    }
  };

  const deleteAccount = async () => {
    const { success } = await ApiHelper.deleteAccount(userId);
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("adminMode");
    return success;
  };

  const acceptTerms = async () => {
    const { success } = await ApiHelper.updateProfile(userProfileId, {
      accepted_term: true,
    });
    if (success) {
      setAuthStates({ ...authStates, hasAcceptedTerms: true });
    }
    return { success };
  };

  const verifyIdentity = async (laserCode) => {
    const { success } = await ApiHelper.verifyIdentity(laserCode);
    if (success) {
      setAuthStates({ ...authStates, isIdentityVerified: true });
    }
    return { success };
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authStates,
        loading,
        userId,
        userProfileId,
        login,
        logout,
        checkAuth,
        acceptTerms,
        verifyIdentity,
        changeAdminMode,
        setToken,
        refreshToken,
        deleteAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
